import { AppFC } from '@types';
import { LandingStarIconStyled, LinksSetWidgetStyled, SpecialistsDescriptionSectionChoiceContainerStyled, SpecialistsDescriptionSectionChoiceHiddenContainerColumnStyled, SpecialistsDescriptionSectionChoiceHiddenContainerStyled, SpecialistsDescriptionSectionChoiceRatingStyled, SpecialistsDescriptionSectionChoiceStyled, SpecialistsDescriptionSectionHiddenImageContainerStyled, SpecialistsDescriptionSectionImageContainerStyled, SpecialistsDescriptionSectionImageStyled, SpecialistsDescriptionSectionParagraphStyled, SpecialistsDescriptionSectionSecondTextBlockStyled, SpecialistsDescriptionSectionTextBlockStyled, SpecialistsDescriptionSectionWidgetStyled, SpecialistsDescriptionSectionWomanImageStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const SpecialistsDescriptionSectionWidget: AppFC = () => {
  return _jsxs(SpecialistsDescriptionSectionWidgetStyled, {
    children: [_jsx(SpecialistsDescriptionSectionHiddenImageContainerStyled, {
      children: _jsx(SpecialistsDescriptionSectionImageStyled //
      , {
        className: "tablet-h:float-left",
        srcSet: "\r /images/landing/main/specialists-description/specialists-description-w320.jpg 320w,\r /images/landing/main/specialists-description/specialists-description-w768.jpg 768w,\r /images/landing/main/specialists-description/specialists-description-w1200.jpg,\r ",
        alt: "\u041C\u043D\u043E\u0433\u043E \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u043E\u0432",
        fadeInEnabled: true
      })
    }), _jsxs(SpecialistsDescriptionSectionTextBlockStyled, {
      children: [_jsxs(SpecialistsDescriptionSectionParagraphStyled, {
        children: ["\u041F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u043E\u0432 \u0443 \u043D\u0430\u0441 \u043D\u0430 \u0441\u0430\u0439\u0442\u0435 \u043C\u043D\u043E\u0433\u043E, \u0438 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0437\u0430\u0446\u0438\u0438 \u0441 \u043F\u043E\u0434\u0445\u043E\u0434\u0430\u043C\u0438 \u0443 \u043D\u0438\u0445 \u0440\u0430\u0437\u043D\u044B\u0435, \u043F\u043E\u044D\u0442\u043E\u043C\u0443", ' ', _jsx("strong", {
          children: "\u043A\u0430\u0436\u0434\u044B\u0439 \u0443\u0447\u0430\u0441\u0442\u043D\u0438\u043A \u043F\u0440\u043E\u0435\u043A\u0442\u0430 \xAB\u0424\u0440\u0435\u0439\u0434\u0435\u0440\u0441\xBB \u043E\u0431\u043B\u0430\u0434\u0430\u0435\u0442 \u0443\u043D\u0438\u043A\u0430\u043B\u044C\u043D\u043E\u0439 \u0432\u043E\u0437\u043C\u043E\u0436\u043D\u043E\u0441\u0442\u044C\u044E \u043F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u0441\u0440\u0430\u0437\u0443 \u043D\u0435\u0441\u043A\u043E\u043B\u044C\u043A\u043E \u0432\u0430\u0440\u0438\u0430\u043D\u0442\u043E\u0432 \u0440\u0435\u0448\u0435\u043D\u0438\u044F \u0441\u0432\u043E\u0435\u0439 \u043F\u0440\u043E\u0431\u043B\u0435\u043C\u044B."
        })]
      }), _jsx(SpecialistsDescriptionSectionParagraphStyled, {
        children: "\u0422\u043E\u043B\u044C\u043A\u043E \u043F\u0440\u0435\u0434\u0441\u0442\u0430\u0432\u044C, \u043D\u0430\u0441\u043A\u043E\u043B\u044C\u043A\u043E \u0434\u0438\u0430\u043C\u0435\u0442\u0440\u0430\u043B\u044C\u043D\u043E \u043F\u0440\u043E\u0442\u0438\u0432\u043E\u043F\u043E\u043B\u043E\u0436\u043D\u044B\u043C\u0438 \u043C\u043E\u0433\u0443\u0442 \u043E\u043A\u0430\u0437\u0430\u0442\u044C\u0441\u044F \u0441\u043E\u0432\u0435\u0442\u044B \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u0430 \u0438 \u0441\u0435\u043A\u0441\u043E\u043B\u043E\u0433\u0430! \u0418\u043B\u0438 - \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u0430, \u043F\u0440\u0430\u043A\u0442\u0438\u043A\u0443\u044E\u0449\u0435\u0433\u043E \u043F\u0441\u0438\u0445\u043E\u0430\u043D\u0430\u043B\u0438\u0437 \u0424\u0440\u0435\u0439\u0434\u0430 \u0438 \u0433\u0435\u0448\u0442\u0430\u043B\u044C\u0442-\u0442\u0435\u0440\u0430\u043F\u0435\u0432\u0442\u0430. \u0410 \u043A\u043E\u0443\u0447\u0438? \u041E\u043D\u0438 \u0432\u0435\u0434\u044C \u0438 \u043C\u0438\u043D\u0443\u0442\u044B \u043F\u0440\u043E\u0436\u0438\u0442\u044C \u043D\u0435 \u043C\u043E\u0433\u0443\u0442 \u0431\u0435\u0437 \u0442\u043E\u0433\u043E, \u0447\u0442\u043E\u0431\u044B \u043D\u0435 \u0437\u0430\u0440\u044F\u0434\u0438\u0442\u044C \u043A\u043E\u0433\u043E-\u043D\u0438\u0431\u0443\u0434\u044C \u043C\u043E\u0442\u0438\u0432\u0430\u0446\u0438\u0435\u0439!"
      })]
    }), _jsxs(SpecialistsDescriptionSectionChoiceHiddenContainerStyled, {
      className: "desktop:float-left",
      children: [_jsx(SpecialistsDescriptionSectionChoiceHiddenContainerColumnStyled, {
        children: _jsxs(SpecialistsDescriptionSectionChoiceContainerStyled, {
          children: [_jsx(LandingStarIconStyled, {}), _jsx(SpecialistsDescriptionSectionChoiceStyled, {
            children: "\u0412\u044B\u0431\u043E\u0440 \u043F\u0440\u043E\u0444\u0435\u0441\u0441\u0438\u043E\u043D\u0430\u043B\u043E\u0432 \u043E\u0433\u0440\u043E\u043C\u043D\u044B\u0439 \u0438 \u043A\u0430\u0436\u0434\u043E\u043C\u0443 \u0438\u0437 \u043D\u0438\u0445 \u043F\u0440\u0438\u0441\u0443\u0436\u0434\u0430\u0435\u0442\u0441\u044F \u0440\u0435\u0439\u0442\u0438\u043D\u0433 \u043D\u0430 \u0431\u0430\u0437\u0435 \u043E\u0446\u0435\u043D\u043E\u043A \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0435\u0439 \u043F\u043B\u0430\u0442\u0444\u043E\u0440\u043C\u044B."
          })]
        })
      }), _jsx(SpecialistsDescriptionSectionChoiceHiddenContainerColumnStyled, {
        children: _jsx(SpecialistsDescriptionSectionImageStyled //
        , {
          srcSet: "\r /images/landing/main/specialists-description/specialists-description-w320.jpg 320w,\r /images/landing/main/specialists-description/specialists-description-w768.jpg 768w,\r /images/landing/main/specialists-description/specialists-description-w1200.jpg,\r ",
          alt: "\u041C\u043D\u043E\u0433\u043E \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u043E\u0432",
          fadeInEnabled: true
        })
      })]
    }), _jsx(SpecialistsDescriptionSectionImageContainerStyled, {
      className: "mobile-h:float-right",
      children: _jsx(SpecialistsDescriptionSectionWomanImageStyled //
      , {
        srcSet: "\r /images/landing/main/specialists-description/specialists-description-woman-w320.png 320w,\r /images/landing/main/specialists-description/specialists-description-woman-w768.png 768w,\r /images/landing/main/specialists-description/specialists-description-woman-w1200.png,\r ",
        alt: "\u0412\u044B\u0431\u043E\u0440 \u043F\u0440\u043E\u0444\u0435\u0441\u0441\u0438\u043E\u043D\u0430\u043B\u043E\u0432",
        fadeInEnabled: true
      })
    }), _jsx(SpecialistsDescriptionSectionChoiceRatingStyled, {
      children: _jsxs(SpecialistsDescriptionSectionChoiceContainerStyled, {
        children: [_jsx(LandingStarIconStyled, {}), _jsx(SpecialistsDescriptionSectionChoiceStyled, {
          children: "\u0412\u044B\u0431\u043E\u0440 \u043F\u0440\u043E\u0444\u0435\u0441\u0441\u0438\u043E\u043D\u0430\u043B\u043E\u0432 \u043E\u0433\u0440\u043E\u043C\u043D\u044B\u0439 \u0438 \u043A\u0430\u0436\u0434\u043E\u043C\u0443 \u0438\u0437 \u043D\u0438\u0445 \u043F\u0440\u0438\u0441\u0443\u0436\u0434\u0430\u0435\u0442\u0441\u044F \u0440\u0435\u0439\u0442\u0438\u043D\u0433 \u043D\u0430 \u0431\u0430\u0437\u0435 \u043E\u0446\u0435\u043D\u043E\u043A \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0435\u0439 \u043F\u043B\u0430\u0442\u0444\u043E\u0440\u043C\u044B."
        })]
      })
    }), _jsxs(SpecialistsDescriptionSectionSecondTextBlockStyled, {
      children: [_jsx(SpecialistsDescriptionSectionParagraphStyled, {
        children: "\u0422\u0430\u043A\u0438\u043C \u043E\u0431\u0440\u0430\u0437\u043E\u043C, \u0442\u044B \u043C\u043E\u0436\u0435\u0448\u044C \u0432\u043E\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C\u0441\u044F \u043E\u043F\u044B\u0442\u043E\u043C \u0434\u0440\u0443\u0433\u0438\u0445 \u0443\u0447\u0430\u0441\u0442\u043D\u0438\u043A\u043E\u0432 \u043F\u0440\u043E\u0435\u043A\u0442\u0430 \u0438 \u0432\u044B\u0431\u0438\u0440\u0430\u0442\u044C \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u043E\u0432 \u043D\u0430 \u0441\u0432\u043E\u0439 \u0432\u043A\u0443\u0441 \u0445\u043E\u0442\u044C \u043A\u0430\u0436\u0434\u044B\u0439 \u0434\u0435\u043D\u044C."
      }), _jsx(SpecialistsDescriptionSectionParagraphStyled, {
        children: "\u0422\u044B \u043E\u0449\u0443\u0449\u0430\u0435\u0448\u044C \u043B\u0451\u0433\u043A\u0443\u044E \u0438\u043D\u0442\u0440\u0438\u0433\u0443 \u0432 \u0432\u043E\u0437\u0434\u0443\u0445\u0435? \u041F\u0440\u0435\u0434\u0432\u043A\u0443\u0448\u0430\u0435\u0448\u044C \u043D\u0435\u043F\u0440\u0438\u043D\u0443\u0436\u0434\u0435\u043D\u043D\u043E\u0435 \u043E\u0431\u0449\u0435\u043D\u0438\u0435 \u0441 \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u043E\u043C? \u042D\u0442\u043E \u0445\u043E\u0440\u043E\u0448\u0438\u0439 \u0437\u043D\u0430\u043A."
      }), _jsx(SpecialistsDescriptionSectionParagraphStyled, {
        children: "\u0417\u043D\u0430\u0447\u0438\u0442 \u0434\u0435\u043B\u043E \u043E\u0441\u0442\u0430\u043B\u043E\u0441\u044C \u0437\u0430 \u043C\u0430\u043B\u044B\u043C \u2013 \u0432\u044B\u0431\u0440\u0430\u0442\u044C \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u0430 \u043D\u0430 \u0441\u0432\u043E\u0439 \u0432\u043A\u0443\u0441 \u0438 \u0437\u0430\u0434\u0430\u0442\u044C \u0435\u043C\u0443 \u0432\u043E\u043F\u0440\u043E\u0441!"
      })]
    }), _jsx(LinksSetWidgetStyled, {
      clientButtonText: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u0430"
    })]
  });
};