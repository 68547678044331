import { AppFC } from '@types';
import { HeroSectionSliderWidget } from '@widgets/landing/landing-client/hero-section-slider';
import { HeroSectionVideoStyled, HeroSectionWidgetContentStyled, HeroSectionWidgetMobileTextStyled, HeroSectionWidgetStyled, HeroSectionWidgetTabletDesktopTextStyled, HeroSectionWidgetTextBlockStyled, HeroSectionWidgetVideoBlockStyled, LinksSetWidgetStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const HeroSectionWidget: AppFC = () => {
  return _jsx(HeroSectionWidgetStyled, {
    children: _jsxs(HeroSectionWidgetContentStyled, {
      children: [_jsxs(HeroSectionWidgetTextBlockStyled, {
        children: [_jsxs(HeroSectionWidgetMobileTextStyled, {
          children: ["\u0412\u044B\u0431\u0438\u0440\u0430\u044F \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u0430", _jsx("br", {}), " \u043D\u0430 \u0424\u0440\u0435\u0439\u0434\u0435\u0440\u0441, \u0442\u044B \u043C\u043E\u0436\u0435\u0448\u044C:"]
        }), _jsxs(HeroSectionWidgetTabletDesktopTextStyled, {
          children: ["\u0412\u044B\u0431\u0438\u0440\u0430\u044F \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u0430 \u043D\u0430 \u0424\u0440\u0435\u0439\u0434\u0435\u0440\u0441,", _jsx("br", {}), "\u0442\u044B \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043B\u044C\u043D\u043E \u043C\u043E\u0436\u0435\u0448\u044C:"]
        }), _jsx(HeroSectionSliderWidget, {})]
      }), _jsxs(HeroSectionWidgetVideoBlockStyled, {
        children: [_jsx(HeroSectionVideoStyled //
        , {
          src: "/video/hero-video.mp4",
          type: "video/mp4; codecs=\"avc1.42E01E, mp4a.40.2\"",
          label: "\u0412 \u044D\u0442\u043E \u0441\u043B\u043E\u0436\u043D\u043E \u043F\u043E\u0432\u0435\u0440\u0438\u0442\u044C",
          srcLang: "ru",
          autoPlay: true,
          muted: true,
          loop: true,
          playsInline: true,
          controls: false,
          preload: "auto",
          width: 2000,
          height: 1500
        }), _jsx(LinksSetWidgetStyled, {})]
      })]
    })
  });
};