import { useCallback, useRef } from 'react';
import { AppFC } from '@types';
import { AspectRatioImgStyled, ChoiceDescriptionStyled, ChoiceParagraphStyled, ChoiceSectionWidgetContentStyled, ChoiceSectionWidgetStyled, ChoiceTitleStyled, LinksSetWidgetStyled, MoreLineStyled, MoreStyled, MoreTextStyled, SpecialistsGridStyled, SpecialistsItemStyled, SpecialistTypeRatingStyled, SpecialistTypeStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const fourStarsImageSrc = '/images/landing/main/choice/4-stars.png';
const fiveStarsImageSrc = '/images/landing/main/choice/5-stars.png';
const specialistTypes = [{
  name: 'психолог',
  rating: fiveStarsImageSrc,
  avatar: '/images/landing/main/choice/psychologist.png'
}, {
  name: 'коуч',
  rating: fiveStarsImageSrc,
  avatar: '/images/landing/main/choice/couch.png'
}, {
  name: 'консультант',
  rating: fourStarsImageSrc,
  avatar: '/images/landing/main/choice/consultant.png'
}, {
  name: 'сексолог',
  rating: fiveStarsImageSrc,
  avatar: '/images/landing/main/choice/sexologist.png'
}];
export const ChoiceSectionWidget: AppFC = () => {
  const sectionRef = useRef<HTMLElement>(null);
  const scrollSectionIntoView = useCallback(() => {
    sectionRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }, []);
  return _jsx(ChoiceSectionWidgetStyled //
  , {
    id: "#choice-section",
    ref: sectionRef,
    children: _jsxs(ChoiceSectionWidgetContentStyled, {
      children: [_jsxs(MoreStyled //
      , {
        href: "#choice-section",
        onClick: scrollSectionIntoView,
        children: [_jsx(MoreLineStyled, {}), _jsx(MoreTextStyled, {
          children: "\u041F\u043E\u0434\u0440\u043E\u0431\u043D\u0435\u0435"
        })]
      }), _jsx(SpecialistsGridStyled, {
        children: specialistTypes.map(type => _jsxs(SpecialistsItemStyled, {
          children: [_jsx(AspectRatioImgStyled //
          , {
            src: type.avatar,
            fadeInEnabled: true
          }), _jsx(SpecialistTypeStyled, {
            children: type.name
          }), _jsx(SpecialistTypeRatingStyled //
          , {
            src: type.rating,
            alt: type.name
          })]
        }, type.name))
      }), _jsxs(ChoiceDescriptionStyled, {
        children: [_jsx(ChoiceTitleStyled, {
          children: "\u0422\u0435\u0431\u0435 \u043D\u0443\u0436\u043D\u043E \u043F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u0441\u043E\u0432\u0435\u0442 \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u0430, \u043A\u043E\u0443\u0447\u0430 \u0438\u043B\u0438 \u0441\u0435\u043A\u0441\u043E\u043B\u043E\u0433\u0430?"
        }), _jsxs(ChoiceParagraphStyled, {
          children: ["\u0422\u0435\u043F\u0435\u0440\u044C \u044D\u0442\u043E \u0442\u0430\u043A \u0436\u0435 \u043F\u0440\u043E\u0441\u0442\u043E, \u043A\u0430\u043A \u043D\u0430\u043F\u0438\u0441\u0430\u0442\u044C \u0441\u043C\u0441 \u0434\u0440\u0443\u0433\u0443. \u042D\u0442\u043E \u0434\u0430\u0436\u0435 \u043F\u0440\u043E\u0449\u0435, \u043F\u043E\u0442\u043E\u043C\u0443 \u0447\u0442\u043E \u043D\u0430\u0441\u0442\u043E\u044F\u0449\u0438\u0439 \u0434\u0440\u0443\u0433 \u2013 \u043E\u0434\u0438\u043D, \u0430", ' ', _jsx("strong", {
            children: "\u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u043E\u0432 \u043D\u0430 \u043D\u0430\u0448\u0435\u0439 \u043F\u043B\u0430\u0442\u0444\u043E\u0440\u043C\u0435 \u043E\u0447\u0435\u043D\u044C \u043C\u043D\u043E\u0433\u043E"
          }), " \u0438 \u043E\u043D\u0438 \u043F\u0440\u044F\u043C\u043E \u0441\u0435\u0439\u0447\u0430\u0441 \u0433\u043E\u0442\u043E\u0432\u044B \u0432\u044B\u0441\u043B\u0443\u0448\u0430\u0442\u044C \u0442\u0435\u0431\u044F, \u0434\u0430\u0442\u044C \u0434\u0435\u043B\u044C\u043D\u044B\u0439 \u0441\u043E\u0432\u0435\u0442 \u0438\u043B\u0438 \u043F\u0440\u043E\u0441\u0442\u043E \u0437\u0430\u0440\u044F\u0434\u0438\u0442\u044C \u0434\u0440\u0430\u0439\u0432\u043E\u043C."]
        }), _jsx(ChoiceParagraphStyled, {
          children: "\u041E\u0441\u0442\u0430\u043B\u043E\u0441\u044C \u0434\u0435\u043B\u043E \u0437\u0430 \u043C\u0430\u043B\u044B\u043C \u2013 \u0432\u044B\u0431\u0440\u0430\u0442\u044C \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u0430 \u043D\u0430 \u0441\u0432\u043E\u0439 \u0432\u043A\u0443\u0441 \u0438 \u0437\u0430\u0434\u0430\u0442\u044C \u0435\u043C\u0443 \u0432\u043E\u043F\u0440\u043E\u0441!"
        }), _jsx(LinksSetWidgetStyled, {})]
      })]
    })
  });
};