import { AppFC } from '@types';
import { InspirationSectionImageStyled, InspirationSectionListItemStyled, InspirationSectionListStyled, InspirationSectionMoreThemesStyled, InspirationSectionNameStyled, InspirationSectionParagraphStyled, InspirationSectionSpecialistInfoStyled, InspirationSectionThemeStyled, InspirationSectionTitleStyled, InspirationSectionWidgetContentStyled, InspirationSectionWidgetStyled, LinksSetWidgetStyled, OnlineIndicatorStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const specialists = [{
  id: 1,
  name: 'Лариса',
  age: 35,
  mainTheme: 'работа',
  moreThemes: 3,
  avatar: '01',
  online: true
}, {
  id: 2,
  name: 'Катя',
  age: 45,
  mainTheme: 'секс',
  moreThemes: 4,
  avatar: '02',
  online: true
}, {
  id: 3,
  name: 'Маша',
  age: 27,
  mainTheme: 'стресс',
  moreThemes: 2,
  avatar: '03',
  online: true
}, {
  id: 4,
  name: 'Алексей',
  age: 45,
  mainTheme: 'семья',
  moreThemes: 2,
  avatar: '04',
  online: true
}, {
  id: 5,
  name: 'Катя',
  age: 41,
  mainTheme: 'секс',
  moreThemes: 2,
  avatar: '05',
  online: true
}, {
  id: 6,
  name: 'Анюта',
  age: 29,
  mainTheme: 'здоровье',
  moreThemes: 3,
  avatar: '06',
  online: true
}, {
  id: 7,
  name: 'Валентин',
  age: 34,
  mainTheme: 'секс',
  moreThemes: 4,
  avatar: '07',
  online: true
}, {
  id: 8,
  name: 'Кристина',
  age: 47,
  mainTheme: 'дружба',
  moreThemes: 2,
  avatar: '08',
  online: true
}, {
  id: 9,
  name: 'София',
  age: 40,
  mainTheme: 'секс',
  moreThemes: 2,
  avatar: '09',
  online: true
}, {
  id: 10,
  name: 'Степан',
  age: 26,
  mainTheme: 'работа',
  moreThemes: 2,
  avatar: '10',
  online: true
}];
export const InspirationSectionWidget: AppFC = () => {
  const specialistsList = specialists.map((specialist, index) => _jsxs(InspirationSectionListItemStyled //
  , {
    inactive: index >= 9,
    children: [_jsx(InspirationSectionImageStyled //
    , {
      srcSet: `
          /images/landing/main/inspiration/psychologist-${specialist.avatar}-320w.jpg 320w,
          /images/landing/main/inspiration/psychologist-${specialist.avatar}-768w.jpg 768w,
          /images/landing/main/inspiration/psychologist-${specialist.avatar}-1600w.jpg 1600w
        `,
      alt: "\u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442"
    }), _jsxs(InspirationSectionSpecialistInfoStyled, {
      children: [_jsxs(InspirationSectionNameStyled, {
        children: [specialist.name, ", ", specialist.age, specialist.online ? _jsx(OnlineIndicatorStyled, {}) : null]
      }), _jsxs(InspirationSectionThemeStyled, {
        children: ["#", specialist.mainTheme, ' ', _jsxs(InspirationSectionMoreThemesStyled, {
          children: ["\u0438 \u0435\u0449\u0435 ", specialist.moreThemes, " \u0442\u0435\u043C\u044B"]
        })]
      })]
    })]
  }, specialist.id));
  return _jsx(InspirationSectionWidgetStyled, {
    children: _jsxs(InspirationSectionWidgetContentStyled, {
      children: [_jsx(InspirationSectionTitleStyled, {
        children: "\u041E \u0441\u0430\u043C\u043E\u043C \u0433\u043B\u0430\u0432\u043D\u043E\u043C"
      }), _jsxs("div", {
        children: [_jsxs(InspirationSectionParagraphStyled, {
          children: ["\u041D\u0430\u0448\u0438 \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u0438 \u043F\u0440\u044F\u043C\u043E \u0441\u0435\u0439\u0447\u0430\u0441 \u0433\u043E\u0442\u043E\u0432\u044B \u0434\u0430\u0442\u044C \u0442\u0435\u0431\u0435 \u043F\u0440\u043E\u0444\u0435\u0441\u0441\u0438\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0439 \u0441\u043E\u0432\u0435\u0442, \u0441\u043D\u0438\u0437\u0438\u0442\u044C \u0443\u0440\u043E\u0432\u0435\u043D\u044C \u0441\u0442\u0440\u0435\u0441\u0441\u0430, \u0438 \u043A\u043E\u043D\u0435\u0447\u043D\u043E \u0436\u0435 - ", _jsx("strong", {
            children: "\u0432\u0434\u043E\u0445\u043D\u043E\u0432\u0438\u0442\u044C."
          })]
        }), _jsx(InspirationSectionParagraphStyled, {
          children: "\u041E\u0441\u0442\u0430\u043B\u043E\u0441\u044C \u0434\u0435\u043B\u043E \u0437\u0430 \u043C\u0430\u043B\u044B\u043C: \u0432\u044B\u0431\u0440\u0430\u0442\u044C \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u0430 \u043D\u0430 \u0441\u0432\u043E\u0439 \u0432\u043A\u0443\u0441 \u0438 \u0437\u0430\u0434\u0430\u0442\u044C \u0435\u043C\u0443 \u0441\u0432\u043E\u0439 \u0441\u0430\u043C\u044B\u0439 \u0433\u043B\u0430\u0432\u043D\u044B\u0439 \u0432\u043E\u043F\u0440\u043E\u0441!"
        })]
      }), _jsx(InspirationSectionListStyled, {
        children: specialistsList
      }), _jsx(LinksSetWidgetStyled //
      , {
        clientButtonText: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u0430",
        mode: "dark"
      })]
    })
  });
};