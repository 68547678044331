import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { Link, LinkExternal } from '@managers/links';
import { ContentDividerStyled, ContentLinksStyled, ContentLinkStyled, ContentLogoLinkStyled, ContentLogoStyled, ContentStyled, LinkContentStyled, LinksWrapStyled } from './styled';
import { ContentDesktopProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ContentDesktop: AppFCC<ContentDesktopProps> = props => {
  const {
    indexRouteDescriptor,
    linksProps,
    emailLinkProps,
    contactLinkProps,
    copyright,
    domain
  } = props;
  return _jsxs(ContentStyled, {
    children: [_jsx(ContentLogoLinkStyled, {
      routeDescriptor: indexRouteDescriptor,
      children: _jsx(ContentLogoStyled //
      , {
        src: "/images/logo-footer.svg",
        alt: domain,
        loaderDisabled: true
      })
    }), _jsxs(ContentLinksStyled, {
      children: [linksProps.map(({
        href,
        children
      }, index) => {
        return _jsx(ContentLinkStyled //
        , {
          href: href,
          children: children
        }, index);
      }), _jsxs(LinksWrapStyled, {
        children: [_jsx(Link, {
          routeDescriptor: ROUTES.contacts.index.getDescriptor(),
          children: _jsx(LinkContentStyled, {
            children: contactLinkProps.children
          })
        }), _jsx(ContentDividerStyled, {
          children: "|"
        }), _jsx(LinkExternal, {
          href: emailLinkProps.href,
          children: _jsx(LinkContentStyled, {
            children: emailLinkProps.children
          })
        })]
      })]
    }), _jsxs("span", {
      children: [" \xA9\xA0", copyright, " "]
    })]
  });
};