import { useEffect } from 'react';
import { AppFCC } from '@types';
import { ChoiceSectionWidget } from '@widgets/landing/landing-client/choice-section';
import { HeroSectionWidget } from '@widgets/landing/landing-client/hero-section';
import { InspirationSectionWidget } from '@widgets/landing/landing-client/inspiration-section';
import { IntrigueSectionWidget } from '@widgets/landing/landing-client/intrigue-section';
import { ListenerSectionWidget } from '@widgets/landing/landing-client/listener-section';
import { SpecialistsDescriptionSectionWidget } from '@widgets/landing/landing-client/specialists-description-section';
import { StartConversationSectionWidget } from '@widgets/landing/landing-client/start-conversation-section';
import { ClientLandingSceneStyled } from './styles';
import { ClientLandingSceneProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ClientLandingScene: AppFCC<ClientLandingSceneProps> = () => {
  useEffect(() => {
    return () => {
      window.scrollTo({
        top: 0
      });
    };
  }, []);
  return _jsxs(ClientLandingSceneStyled, {
    children: [_jsx(HeroSectionWidget, {}), _jsx(ChoiceSectionWidget, {}), _jsx(ListenerSectionWidget, {}), _jsx(StartConversationSectionWidget, {}), _jsx(IntrigueSectionWidget, {}), _jsx(SpecialistsDescriptionSectionWidget, {}), _jsx(InspirationSectionWidget, {})]
  });
};