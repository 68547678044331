import { useCallback, useEffect, useRef, useState } from 'react';
import { POPUP } from '@config/popups';
import { NAVIGATION_MODE, QUERY_PARAM } from '@config/routing';
import { differenceWithCaseInsensitive } from '@helpers/data';
import { isClient } from '@helpers/member';
import { isPopupId, parseQuerySource } from '@helpers/popups';
import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { accountGetIsAuthorized } from '@selectors/account';
import { currentClientGetIsFetchingMe } from '@selectors/current-client';
import { currentMemberGetExists, currentMemberGetProfileIsFilledAndApproved, currentMemberGetRoleType } from '@selectors/current-member';
import { currentSpecialistGetIsFetchingMe } from '@selectors/current-specialist';
import { useNavigator } from '@hooks/routing';
import { useTypedSelector, useTypedStore } from '@hooks/store';
import { LoadingScreen } from '@kit/loading-screen';
import { OVERLAY_BG_COLOR } from '@kit/overlay';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const PublicOnly: AppFCC = props => {
  const {
    children
  } = props;
  const navigator = useNavigator();
  const store = useTypedStore();
  const isAuthorized = useTypedSelector(accountGetIsAuthorized);
  const currentMemberExists = useTypedSelector(currentMemberGetExists);
  const roleType = useTypedSelector(currentMemberGetRoleType);
  const isFetchingClientMe = useTypedSelector(currentClientGetIsFetchingMe);
  const isFetchingSpecialistMe = useTypedSelector(currentSpecialistGetIsFetchingMe);
  const _isClient = isClient(roleType);
  const isFetchingMe = _isClient ? isFetchingClientMe : isFetchingSpecialistMe;
  const isInitiallyAuthorized = useRef(isAuthorized);
  const [loadingScreenIsShown, setLoadingScreenIsShown] = useState(true);
  useEffect(() => {
    if (isInitiallyAuthorized.current && (isFetchingMe || !currentMemberExists)) {
      return;
    }
    setLoadingScreenIsShown(false);
  }, [currentMemberExists, isFetchingMe]);
  const redirect = useCallback(async () => {
    const state = store.getState();
    const profileIsFilledAndApproved = currentMemberGetProfileIsFilledAndApproved(state);
    const pathname = navigator.getPathname();
    const match = navigator.findMatch(pathname);
    const query = navigator.getQuery();
    const queryPopupIds = parseQuerySource(query) //
    .filter(isPopupId);
    const params = {
      ...query,
      ...match?.params,
      [QUERY_PARAM.SOURCE]: differenceWithCaseInsensitive(queryPopupIds, [POPUP.ACCOUNT_LOGIN_MODAL, POPUP.ACCOUNT_REGISTER_MODAL, POPUP.LANDING_CLIENT_REGISTER_MODAL, POPUP.LANDING_PSYCHOLOGIST_REGISTER_MODAL]),
      [QUERY_PARAM.STEP]: undefined
    };
    const routeDescriptor = profileIsFilledAndApproved ? ROUTES.search.index.getDescriptor(params,
    //
    {
      mode: NAVIGATION_MODE.REPLACE,
      resetScroll: true
    }) : ROUTES.profile.edit.index.getDescriptor(params,
    //
    {
      mode: NAVIGATION_MODE.REPLACE,
      resetScroll: true
    });
    await navigator.navigate(routeDescriptor);
  }, [store, navigator]);
  useEffect(() => {
    if (isFetchingMe || !currentMemberExists) {
      return;
    }
    redirect();
  }, [currentMemberExists, isFetchingMe, redirect]);
  return _jsxs(_Fragment, {
    children: [children, !loadingScreenIsShown ? null : _jsx(LoadingScreen, {
      overlayBgColor: OVERLAY_BG_COLOR.WHITE_NON_TRANSPARENT
    })]
  });
};