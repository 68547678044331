import { getOriginDomain } from '@helpers/url';
import { AppFC } from '@types';
import { FifthChildIconStyled, FirstHeartIconStyled, FourthBrokenHeartIconStyled, IntrigueSectionContainerStyled, IntrigueSectionDescriptionStyled, IntrigueSectionIconContainerStyled, IntrigueSectionImageStyled, IntrigueSectionParagraphStyled, IntrigueSectionThemeStyled, IntrigueSectionThemeTitleStyled, IntrigueSectionTitleStyled, IntrigueSectionWidgetStyled, LinksSetWidgetStyled, SecondStrawberryIconStyled, SeventhSmileIconStyled, SixthRocketIconStyled, ThemesListStyled, ThirdHandIconStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const themes = [{
  id: 1,
  title: 'отношения в паре и поиск любви',
  icon: _jsx(FirstHeartIconStyled, {})
}, {
  id: 2,
  title: 'эротические фантазии',
  icon: _jsx(SecondStrawberryIconStyled, {})
}, {
  id: 3,
  title: 'дружба',
  icon: _jsx(ThirdHandIconStyled, {})
}, {
  id: 4,
  title: 'измены',
  icon: _jsx(FourthBrokenHeartIconStyled, {})
}, {
  id: 5,
  title: 'отношения с детьми',
  icon: _jsx(FifthChildIconStyled, {})
}, {
  id: 6,
  title: 'работа и бизнес',
  icon: _jsx(SixthRocketIconStyled, {})
}, {
  id: 7,
  title: 'методы снятия стресса',
  icon: _jsx(SeventhSmileIconStyled, {})
}];
export const IntrigueSectionWidget: AppFC = () => {
  const themesList = themes.map((theme, index) => {
    const isFirst = index === 0;
    return _jsxs(IntrigueSectionThemeStyled //
    , {
      isFirst: isFirst,
      children: [_jsx(IntrigueSectionIconContainerStyled, {
        children: theme.icon
      }), _jsx(IntrigueSectionThemeTitleStyled, {
        isFirst: isFirst,
        children: theme.title
      })]
    }, theme.id);
  });
  return _jsxs(IntrigueSectionWidgetStyled, {
    children: [_jsxs(IntrigueSectionContainerStyled, {
      children: [_jsx(IntrigueSectionImageStyled //
      , {
        className: "tablet-h:float-right",
        srcSet: "\r /images/landing/main/intrigue/intrigue-w320.jpg 320w,\r /images/landing/main/intrigue/intrigue-w768.jpg 768w,\r /images/landing/main/intrigue/intrigue-w1600.jpg,\r ",
        alt: "\u041F\u043E\u0447\u0443\u0432\u0441\u0442\u0432\u0443\u0439 \u043B\u0435\u0433\u043A\u0443\u044E \u0438\u043D\u0442\u0440\u0438\u0433\u0443",
        fadeInEnabled: true
      }), _jsx(IntrigueSectionTitleStyled, {
        children: "\u041F\u043E\u0447\u0443\u0432\u0441\u0442\u0432\u0443\u0439 \u043B\u0435\u0433\u043A\u0443\u044E \u0438\u043D\u0442\u0440\u0438\u0433\u0443"
      }), _jsxs(IntrigueSectionDescriptionStyled, {
        children: [_jsxs(IntrigueSectionParagraphStyled, {
          children: ["\u041A\u0430\u043A\u0438\u0435 \u043F\u0440\u043E\u0431\u043B\u0435\u043C\u044B \u043C\u043E\u0436\u043D\u043E \u043E\u0431\u0441\u0443\u0434\u0438\u0442\u044C \u0441 \u043D\u0430\u0448\u0438\u043C\u0438 \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u0430\u043C\u0438?", ' ', _jsx("strong", {
            children: "\u0412\u043E\u2011\u043F\u0435\u0440\u0432\u044B\u0445, \u0430\u0431\u0441\u043E\u043B\u044E\u0442\u043D\u043E \u043B\u044E\u0431\u044B\u0435!"
          })]
        }), _jsx(IntrigueSectionParagraphStyled, {
          children: "\u041C\u044B \u043F\u0440\u0438\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0435\u043C\u0441\u044F \u0441\u0442\u0440\u043E\u0436\u0430\u0439\u0448\u0435\u0439 \u043F\u043E\u043B\u0438\u0442\u0438\u043A\u0438 \u043A\u043E\u043D\u0444\u0438\u0434\u0435\u043D\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u0438 \u0438 \u0433\u043E\u0440\u0434\u0438\u043C\u0441\u044F \u0434\u043E\u0432\u0435\u0440\u0438\u0435\u043C, \u043A\u043E\u0442\u043E\u0440\u043E\u0435 \u0437\u0430\u0441\u043B\u0443\u0436\u0438\u043B\u0438."
        }), _jsxs(IntrigueSectionParagraphStyled, {
          children: ["\u0412\u043E-\u0432\u0442\u043E\u0440\u044B\u0445, ", _jsx("strong", {
            children: "\u0430\u043D\u043E\u043D\u0438\u043C\u043D\u044B\u0435 \u043E\u043F\u0440\u043E\u0441\u044B \u043F\u043E\u043A\u0430\u0437\u044B\u0432\u0430\u044E\u0442"
          }), ", \u0447\u0442\u043E \u0441\u0430\u043C\u044B\u0435 \u043E\u0431\u0441\u0443\u0436\u0434\u0430\u0435\u043C\u044B\u0435 \u0442\u0435\u043C\u044B \u0441\u0440\u0435\u0434\u0438 \u0443\u0447\u0430\u0441\u0442\u043D\u0438\u043A\u043E\u0432 \u043F\u0440\u043E\u0435\u043A\u0442\u0430 ", getOriginDomain(), " \u2013 \u044D\u0442\u043E"]
        })]
      })]
    }), _jsx(ThemesListStyled, {
      children: themesList
    }), _jsx(LinksSetWidgetStyled //
    , {
      className: "desktop:float-right",
      clientButtonText: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u0430"
    })]
  });
};