import { getOriginDomain } from '@helpers/url';
import { AppFC } from '@types';
import { CalendarIconStyled, LackOfMoneyIconStyled, LinksSetWidgetStyled, ListenerSectionCalendarIconContainerStyled, ListenerSectionCircumstancesContainerStyled, ListenerSectionCircumstanceStyled, ListenerSectionCircumstanceTextStyled, ListenerSectionClearedParagraphStyled, ListenerSectionCloudStyled, ListenerSectionImageStyled, ListenerSectionLackOfMoneyIconContainerStyled, ListenerSectionParagraphStyled, ListenerSectionQuestionIconContainerStyled, ListenerSectionSecondImageStyled, ListenerSectionTextContainerStyled, ListenerSectionTitleContainerStyled, ListenerSectionTitleStyled, ListenerSectionWidgetStyled, QuestionIconStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ListenerSectionWidget: AppFC = () => {
  const domain = getOriginDomain();
  return _jsxs(ListenerSectionWidgetStyled, {
    children: [_jsxs(ListenerSectionTitleContainerStyled, {
      children: [_jsx(ListenerSectionImageStyled //
      , {
        srcSet: "\r /images/landing/main/listener/listener-w320.jpg 320w,\r /images/landing/main/listener/listener-w768.jpg 768w,\r /images/landing/main/listener/listener-w1600.jpg,\r ",
        alt: "\u0412\u0441\u0435\u0433\u0434\u0430 \u0437\u0434\u0435\u0441\u044C, \u0447\u0442\u043E\u0431\u044B \u0432\u044B\u0441\u043B\u0443\u0448\u0430\u0442\u044C",
        fadeInEnabled: true
      }), _jsxs(ListenerSectionTitleStyled, {
        children: [_jsx("span", {
          children: "\u0412\u0441\u0435\u0433\u0434\u0430 \u0437\u0434\u0435\u0441\u044C,"
        }), _jsx("span", {
          children: "\u0447\u0442\u043E\u0431\u044B \u0432\u044B\u0441\u043B\u0443\u0448\u0430\u0442\u044C"
        })]
      }), _jsxs(ListenerSectionTextContainerStyled, {
        children: [_jsx(ListenerSectionParagraphStyled, {
          children: "\u041F\u0440\u0438\u0432\u0435\u0442!"
        }), _jsxs(ListenerSectionParagraphStyled, {
          children: ["\u041C\u044B \u0440\u0430\u0434\u044B \u043F\u0440\u0438\u0432\u0435\u0442\u0441\u0442\u0432\u043E\u0432\u0430\u0442\u044C \u0442\u0435\u0431\u044F \u043D\u0430 ", domain, ", \u0433\u0434\u0435 \u043E\u0431\u044B\u0447\u043D\u044B\u0435 \u043B\u044E\u0434\u0438 \u0441\u043E \u0441\u0432\u043E\u0438\u043C\u0438 \u043F\u0440\u043E\u0431\u043B\u0435\u043C\u0430\u043C\u0438 \u0438 \u0436\u0438\u0437\u043D\u0435\u043D\u043D\u044B\u043C\u0438 \u0441\u0438\u0442\u0443\u0430\u0446\u0438\u044F\u043C\u0438, \u0441\u043E\u0432\u0435\u0442\u0443\u044E\u0442\u0441\u044F \u0441 \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u0430\u043C\u0438 \u0432 \u043C\u0435\u0441\u0441\u0435\u043D\u0434\u0436\u0435\u0440\u0435, \u0430 \u0442\u0430\u043A\u0436\u0435 \u043F\u043E\u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043E\u043C \u0432\u0438\u0434\u0435\u043E \u0438 \u0430\u0443\u0434\u0438\u043E \u0437\u0432\u043E\u043D\u043A\u043E\u0432 \u043F\u0440\u044F\u043C\u043E \u043D\u0430 \u043D\u0430\u0448\u0435\u043C \u0441\u0430\u0439\u0442\u0435."]
        })]
      })]
    }), _jsx(ListenerSectionCloudStyled, {
      children: "\u041C\u044B \u0432\u0435\u0440\u0438\u043C, \u0447\u0442\u043E \u0441\u043E\u0432\u0435\u0442 \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u0430, \u043C\u043E\u0442\u0438\u0432\u0430\u0446\u0438\u044F \u043E\u0442 \u043A\u043E\u0443\u0447\u0430 \u0438\u043B\u0438 \u043F\u0438\u043A\u0430\u043D\u0442\u043D\u0430\u044F \u0440\u0435\u043C\u0430\u0440\u043A\u0430 \u043E\u0442 \u0441\u0435\u043A\u0441\u043E\u043B\u043E\u0433\u0430 \u0434\u043E\u043B\u0436\u043D\u044B \u0431\u044B\u0442\u044C \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u0435\u0435 \u0434\u043B\u044F \u043A\u0430\u0436\u0434\u043E\u0433\u043E \u0438\u0437 \u043D\u0430\u0441."
    }), _jsx(ListenerSectionSecondImageStyled //
    , {
      className: "mobile-h:float-left tablet:float-left",
      srcSet: "\r /images/landing/main/listener/listener-2-w320.jpg 320w,\r /images/landing/main/listener/listener-2-w768.jpg 768w,\r /images/landing/main/listener/listener-2-w1600.jpg,\r ",
      alt: "\u0412\u0441\u0435\u0433\u0434\u0430 \u0437\u0434\u0435\u0441\u044C, \u0447\u0442\u043E\u0431\u044B \u0432\u044B\u0441\u043B\u0443\u0448\u0430\u0442\u044C",
      fadeInEnabled: true
    }), _jsx(ListenerSectionParagraphStyled, {
      children: "\u041C\u044B \u0432\u0435\u0440\u0438\u043C, \u0447\u0442\u043E \u043C\u043D\u043E\u0433\u0438\u0435 \u0445\u043E\u0442\u044F\u0442 \u043F\u043E\u0434\u0435\u043B\u0438\u0442\u044C\u0441\u044F \u0441\u0432\u043E\u0438\u043C\u0438 \u043F\u0440\u043E\u0431\u043B\u0435\u043C\u0430\u043C\u0438 \u0441 \u043F\u0440\u043E\u0444\u0435\u0441\u0441\u0438\u043E\u043D\u0430\u043B\u043E\u043C, \u043D\u043E \u043D\u0435 \u0443 \u0432\u0441\u0435\u0445 \u043A \u044D\u0442\u043E\u043C\u0443 \u0440\u0430\u0441\u043F\u043E\u043B\u0430\u0433\u0430\u044E\u0442 \u0436\u0438\u0437\u043D\u0435\u043D\u043D\u044B\u0435 \u043E\u0431\u0441\u0442\u043E\u044F\u0442\u0435\u043B\u044C\u0441\u0442\u0432\u0430, \u043A\u043E\u0442\u043E\u0440\u044B\u0435 \u043E\u0442\u043E\u0434\u0432\u0438\u0433\u0430\u044E\u0442 \u0432\u0441\u0442\u0440\u0435\u0447\u0443 \u0441 \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u043E\u043C \u043D\u0430 \u0432\u0442\u043E\u0440\u043E\u0439 \u043F\u043B\u0430\u043D:"
    }), _jsxs(ListenerSectionCircumstancesContainerStyled, {
      children: [_jsxs(ListenerSectionCircumstanceStyled, {
        children: [_jsx(ListenerSectionCalendarIconContainerStyled, {
          children: _jsx(CalendarIconStyled, {})
        }), _jsxs(ListenerSectionCircumstanceTextStyled, {
          children: ["\u043F\u043E\u0441\u0442\u043E\u044F\u043D\u043D\u0430\u044F", _jsx("br", {}), "\u0437\u0430\u043D\u044F\u0442\u043E\u0441\u0442\u044C"]
        })]
      }), _jsxs(ListenerSectionCircumstanceStyled, {
        children: [_jsx(ListenerSectionQuestionIconContainerStyled, {
          children: _jsx(QuestionIconStyled, {})
        }), _jsxs(ListenerSectionCircumstanceTextStyled, {
          children: ["\u043D\u0435\u0443\u0432\u0435\u0440\u0435\u043D\u043D\u043E\u0441\u0442\u044C", _jsx("br", {}), "\u0432 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u0435"]
        })]
      }), _jsxs(ListenerSectionCircumstanceStyled, {
        children: [_jsx(ListenerSectionLackOfMoneyIconContainerStyled, {
          children: _jsx(LackOfMoneyIconStyled, {})
        }), _jsxs(ListenerSectionCircumstanceTextStyled, {
          children: ["\u043D\u0435\u0445\u0432\u0430\u0442\u043A\u0430", _jsx("br", {}), "\u0441\u0440\u0435\u0434\u0441\u0442\u0432"]
        })]
      })]
    }), _jsxs("div", {
      children: [_jsx(ListenerSectionClearedParagraphStyled, {
        children: "\u0422\u0430\u043A\u043E\u0435 \u043F\u043E\u043B\u043E\u0436\u0435\u043D\u0438\u0435 \u0432\u0435\u0449\u0435\u0439 \u0438 \u0441\u0442\u0430\u043B\u043E \u043E\u0441\u043D\u043E\u0432\u043E\u0439 \u0434\u043B\u044F \u0441\u043E\u0437\u0434\u0430\u043D\u0438\u044F \u043D\u0430\u0448\u0435\u0433\u043E \u043F\u0440\u043E\u0435\u043A\u0442\u0430. \u0422\u0435\u043C \u043D\u0435 \u043C\u0435\u043D\u0435\u0435, \u0432 \u0441\u0432\u043E\u0438\u0445 \u0440\u044F\u0434\u0430\u0445 \u043C\u044B \u043A\u043E\u043D\u0435\u0447\u043D\u043E \u0436\u0435 \u0440\u0430\u0434\u044B \u0432\u0438\u0434\u0435\u0442\u044C \u043D\u0435 \u0442\u043E\u043B\u044C\u043A\u043E \u043D\u043E\u0432\u044B\u0445, \u043D\u043E \u0438 \u043F\u0440\u043E\u0434\u0432\u0438\u043D\u0443\u0442\u044B\u0445 \u043F\u043E\u0441\u043B\u0435\u0434\u043E\u0432\u0430\u0442\u0435\u043B\u0435\u0439 \u0441\u0442\u0430\u0440\u0438\u043D\u044B \u0424\u0440\u0435\u0439\u0434\u0430!"
      }), _jsx(ListenerSectionParagraphStyled, {
        children: _jsx("strong", {
          children: "\u041C\u044B \u0443\u0432\u0435\u0440\u0435\u043D\u044B, \u0447\u0442\u043E \u0441\u043C\u043E\u0436\u0435\u043C \u043F\u0440\u0438\u0431\u043B\u0438\u0437\u0438\u0442\u044C \u0442\u0435\u0431\u044F \u043A \u0440\u0435\u0448\u0435\u043D\u0438\u044E \u0442\u0432\u043E\u0438\u0445 \u0437\u0430\u0434\u0430\u0447!"
        })
      }), _jsx(ListenerSectionParagraphStyled, {
        children: "\u0414\u043B\u044F \u044D\u0442\u043E\u0433\u043E \u0442\u0435\u0431\u0435 \u043D\u0443\u0436\u043D\u043E \u0441\u0434\u0435\u043B\u0430\u0442\u044C \u0432\u0441\u0435\u0433\u043E \u043B\u0438\u0448\u044C \u043E\u0434\u0438\u043D \u043C\u0430\u043B\u0435\u043D\u044C\u043A\u0438\u0439 \u0448\u0430\u0433 - \u0434\u0430\u0442\u044C \u0448\u0430\u043D\u0441 \u043D\u0430\u0448\u0438\u043C \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u0430\u043C. \u042D\u0442\u043E \u0443\u0432\u043B\u0435\u043A\u0430\u0442\u0435\u043B\u044C\u043D\u043E, \u044D\u0442\u043E \u044D\u0444\u0444\u0435\u043A\u0442\u0438\u0432\u043D\u043E \u0438 \u044D\u0442\u043E \u0441\u0442\u043E\u0438\u0442 \u043F\u043E\u043F\u0440\u043E\u0431\u043E\u0432\u0430\u0442\u044C!"
      }), _jsxs(ListenerSectionParagraphStyled, {
        children: ["\u0412\u0441\u0435\u0433\u0434\u0430 \u0437\u0434\u0435\u0441\u044C, \u0447\u0442\u043E\u0431\u044B \u0432\u044B\u0441\u043B\u0443\u0448\u0430\u0442\u044C, \u043A\u043E\u043C\u0430\u043D\u0434\u0430 ", domain]
      })]
    }), _jsx(LinksSetWidgetStyled, {
      clientButtonText: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u0430"
    })]
  });
};