import { useMemo, useState } from 'react';
import { AppFC } from '@types';
import { useVisibility, UseVisibilityArgs } from '@hooks/visibility';
import { LinksSetWidgetStyled, MenImageStyled, MenProblemItemStyled, MenProblemsListStyled, MenProblemsStyled, StartConversationSectionScrollContainerStyled, StartConversationSectionScrollStyled, StartConversationSectionSwipeStyled, StartConversationSectionTitleStyled, StartConversationSectionWidgetStyled, WomenImageStyled, WomenProblemItemStyled, WomenProblemsListStyled, WomenProblemsStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const herProblemsList = [{
  id: 1,
  text: _jsx("span", {
    className: "block tablet:w-14 tablet-h:w-14 desktop:w-18x625 desktop-lg:w-17x625",
    children: "\u043C\u0435\u043D\u044F \u0431\u0440\u043E\u0441\u0438\u043B \u043F\u0430\u0440\u0435\u043D\u044C \u0438 \u043C\u043D\u0435 \u043D\u0435\u043A\u043E\u043C\u0443 \u043E\u0431 \u044D\u0442\u043E\u043C \u0440\u0430\u0441\u0441\u043A\u0430\u0437\u0430\u0442\u044C"
  })
}, {
  id: 2,
  text: _jsx("span", {
    className: "block tablet:w-18x5 tablet-h:w-18x5 desktop:w-24x625 desktop-lg:w-23x75",
    children: "\u043C\u043D\u0435 \u043A\u0430\u0436\u0435\u0442\u0441\u044F, \u0447\u0442\u043E \u043C\u043E\u0439 \u043C\u0443\u0436 \u043C\u043D\u0435 \u0438\u0437\u043C\u0435\u043D\u044F\u0435\u0442"
  })
}, {
  id: 3,
  text: _jsx("span", {
    className: "block tablet:w-20x875 tablet-h:w-20x875 desktop:w-27x875 desktop-lg:w-26x5",
    children: "\u0445\u043E\u0447\u0443 \u0441\u0442\u0430\u0442\u044C \u043D\u0435\u0437\u0430\u0432\u0438\u0441\u0438\u043C\u043E\u0439 \u0438 \u0443\u0432\u0435\u0440\u0435\u043D\u043D\u043E\u0439 \u0432 \u0441\u0435\u0431\u0435, \u043D\u043E \u043D\u0435 \u0437\u043D\u0430\u044E \u043A\u0430\u043A"
  })
}, {
  id: 4,
  text: 'я заедаю проблемы тортиками, и хочу избавиться от этой привычки'
}, {
  id: 5,
  text: _jsx("span", {
    className: "block tablet:w-29x375 tablet-h:w-29x375 desktop:w-39x125 desktop-lg:w-38",
    children: "\u044F \u0445\u043E\u0447\u0443 \u043F\u043E\u0441\u0432\u044F\u0442\u0438\u0442\u044C \u0441\u0435\u0431\u044F \u043A\u0430\u0440\u044C\u0435\u0440\u0435, \u043D\u043E \u0431\u043E\u044E\u0441\u044C, \u0447\u0442\u043E \u0438\u0437-\u0437\u0430 \u044D\u0442\u043E\u0433\u043E \u043C\u043E\u0436\u0435\u0442 c\u0435\u0440\u044C\u0435\u0437\u043D\u043E \u043F\u043E\u0441\u0442\u0440\u0430\u0434\u0430\u0442\u044C \u043C\u043E\u044F \u0441\u0435\u043C\u044C\u044F"
  })
}, {
  id: 6,
  text: 'мне надоело тащить все на себе, хочу выйти из этого замкнутого круга'
}, {
  id: 7,
  text: 'у меня никак не получается выйти замуж, а у подруг уже по двое детей'
}, {
  id: 8,
  text: 'мне нравится муж лучшей подруги, и я не знаю как поступить'
}, {
  id: 9,
  text: _jsx("span", {
    className: "block tablet:w-23 tablet-h:w-23 desktop:w-30x75 desktop-lg:w-28x5",
    children: "\u043C\u043E\u0439 \u043F\u0430\u0440\u0435\u043D\u044C \u0442\u0440\u0435\u0431\u0443\u0435\u0442 \u043E\u0442 \u043C\u0435\u043D\u044F \u0441\u043B\u0438\u0448\u043A\u043E\u043C \u043C\u043D\u043E\u0433\u043E\u0433\u043E \u0432 \u043F\u043E\u0441\u0442\u0435\u043B\u0438 \u0438 \u044F \u043D\u0435 \u0437\u043D\u0430\u044E \u0441\u043E\u0433\u043B\u0430\u0448\u0430\u0442\u044C\u0441\u044F \u0438\u043B\u0438 \u043D\u0435\u0442"
  })
}];
const hisProblemsList = [{
  id: 1,
  text: _jsx("span", {
    className: "block tablet:w-12x125 tablet-h:w-12x125 desktop:w-16x25",
    children: "\u044F \u0436\u0435\u043D\u0430\u0442, \u043D\u043E \u043B\u044E\u0431\u043B\u044E \u0434\u0440\u0443\u0433\u0443\u044E"
  })
}, {
  id: 2,
  text: _jsx("span", {
    className: "block tablet:w-17x5 tablet-h:w-17x5 desktop:w-22x5",
    children: "\u044F \u0445\u043E\u0447\u0443 \u0443\u0441\u043D\u0443\u0442\u044C \u0438 \u043D\u0435 \u043C\u043E\u0433\u0443, \u043C\u043D\u0435 \u0442\u0440\u0435\u0432\u043E\u0436\u043D\u043E"
  })
}, {
  id: 3,
  text: _jsx("span", {
    className: "block tablet:w-16x5 tablet-h:w-16x5 desktop:w-22 desktop-lg:w-21",
    children: "\u043C\u044B \u0441 \u0436\u0435\u043D\u043E\u0439 \u043E\u0445\u043B\u0430\u0434\u0435\u043B\u0438 \u0434\u0440\u0443\u0433 \u043A \u0434\u0440\u0443\u0433\u0443 \u0438 \u044F \u043D\u0435 \u0437\u043D\u0430\u044E, \u043A\u0430\u043A \u044D\u0442\u043E \u0438\u0441\u043F\u0440\u0430\u0432\u0438\u0442\u044C"
  })
}, {
  id: 4,
  text: _jsx("span", {
    className: "block tablet:w-26x375 tablet-h:w-26x375 desktop:w-35x25",
    children: "\u043C\u043D\u0435 \u043D\u0430\u0434\u043E\u0435\u043B\u0430 \u044D\u0442\u0430 \u0441\u043A\u0443\u0447\u043D\u0430\u044F \u0440\u0430\u0431\u043E\u0442\u0430, \u0445\u043E\u0447\u0443 \u0443\u0432\u043E\u043B\u0438\u0442\u044C\u0441\u044F, \u043D\u043E \u0431\u043E\u044E\u0441\u044C \u043E\u0441\u0442\u0430\u0442\u044C\u0441\u044F \u043D\u0438 \u0441 \u0447\u0435\u043C"
  })
}, {
  id: 5,
  text: 'я иду на собеседование и не могу настроиться на победу'
}, {
  id: 6,
  text: 'хочется эмоций, как раньше, но вдохновение куда-то пропало'
}, {
  id: 7,
  text: 'хочу начать своё дело, но что-то меня останавливает'
}, {
  id: 8,
  text: _jsx("span", {
    className: "block tablet:w-27x75 tablet-h:w-27x75 desktop:w-37 desktop-lg:w-29x5",
    children: "\u0445\u043E\u0447\u0443 \u0437\u0430\u0440\u0430\u0431\u0430\u0442\u044B\u0432\u0430\u0442\u044C \u0431\u043E\u043B\u044C\u0448\u0435, \u043F\u043E\u0442\u043E\u043C\u0443 \u0447\u0442\u043E \u044F \u0434\u043E\u0441\u0442\u043E\u0438\u043D \u0431\u043E\u043B\u044C\u0448\u0435\u0433\u043E, \u043D\u043E \u0434\u0430\u0436\u0435 \u043D\u0435 \u0437\u043D\u0430\u044E \u0441 \u0447\u0435\u0433\u043E \u043D\u0430\u0447\u0430\u0442\u044C"
  })
}, {
  id: 9,
  text: _jsx("span", {
    className: "block tablet:w-27x75 tablet-h:w-27x75 desktop:w-37 desktop-lg:w-24",
    children: "\u043C\u043E\u044F \u0434\u0435\u0432\u0443\u0448\u043A\u0430 \u0441\u043B\u0438\u0448\u043A\u043E\u043C \u0441\u0442\u0435\u0441\u043D\u0438\u0442\u0435\u043B\u044C\u043D\u0430 \u0432 \u043F\u043E\u0441\u0442\u0435\u043B\u0438 \u0438 \u044F \u043D\u0435 \u0437\u043D\u0430\u044E, \u043A\u0430\u043A \u0435\u0435 \u0440\u0430\u0441\u0441\u043B\u0430\u0431\u0438\u0442\u044C"
  })
}];
export const StartConversationSectionWidget: AppFC = () => {
  const [containerElement, setContainerElement] = useState<null | HTMLDivElement>();
  const [menProblemsElement, setMenProblemsElement] = useState<null | HTMLDivElement>();
  const useVisibilityArgs: UseVisibilityArgs = useMemo(() => ({
    target: menProblemsElement,
    root: containerElement,
    options: {
      threshold: 0.75
    }
  }), [containerElement, menProblemsElement]);
  const {
    isVisible: menProblemsIsVisible
  } = useVisibility(useVisibilityArgs);
  const womenProblems = useMemo(() => {
    return herProblemsList.map(problem => _jsx(WomenProblemItemStyled //
    , {
      isActive: !menProblemsIsVisible,
      children: problem.text
    }, problem.id));
  }, [menProblemsIsVisible]);
  const menProblems = useMemo(() => {
    return hisProblemsList.map(problem => _jsx(MenProblemItemStyled //
    , {
      isActive: menProblemsIsVisible,
      children: problem.text
    }, problem.id));
  }, [menProblemsIsVisible]);
  return _jsxs(StartConversationSectionWidgetStyled, {
    children: [_jsxs(StartConversationSectionTitleStyled, {
      children: [_jsx("span", {
        children: "\u0421 \u0447\u0435\u0433\u043E \u043D\u0430\u0447\u0430\u0442\u044C"
      }), _jsx("span", {
        children: "\u0440\u0430\u0437\u0433\u043E\u0432\u043E\u0440 \u0441 \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u043E\u043C?"
      })]
    }), _jsx(StartConversationSectionScrollContainerStyled, {
      ref: setContainerElement,
      children: _jsxs(StartConversationSectionScrollStyled, {
        children: [_jsxs(WomenProblemsStyled, {
          children: [_jsx(WomenImageStyled, {
            className: "block tablet:float-left tablet-h:float-left",
            src: "/images/landing/main/start-conversation/women-problems.png",
            alt: "\u0421 \u0447\u0435\u0433\u043E \u043D\u0430\u0447\u0430\u0442\u044C \u0440\u0430\u0437\u0433\u043E\u0432\u043E\u0440 \u0436\u0435\u043D\u0449\u0438\u043D\u0435?"
          }), _jsx(WomenProblemsListStyled, {
            children: womenProblems
          })]
        }), _jsx(StartConversationSectionSwipeStyled, {}), _jsxs(MenProblemsStyled, {
          ref: setMenProblemsElement,
          children: [_jsx(MenImageStyled, {
            className: "block tablet:float-right tablet-h:float-right",
            src: "/images/landing/main/start-conversation/men-problems.png",
            alt: "\u0421 \u0447\u0435\u0433\u043E \u043D\u0430\u0447\u0430\u0442\u044C \u0440\u0430\u0437\u0433\u043E\u0432\u043E\u0440 \u043C\u0443\u0436\u0447\u0438\u043D\u0435?"
          }), _jsx(MenProblemsListStyled, {
            children: menProblems
          })]
        })]
      })
    }), _jsx(LinksSetWidgetStyled, {
      clientButtonText: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433\u0430"
    })]
  });
};