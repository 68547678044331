import { useMemo } from 'react';
import { POPUP } from '@config/popups';
import { QUERY_PARAM } from '@config/routing';
import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { useNavigator } from '@hooks/routing';
import { LinksSetWidgetProps } from '@widgets/landing/landing-client/links-set/types';
import { BUTTON_BASE_COLORED_COLOR } from '@kit/buttons/button-base-colored';
import { ArrowRightIconStyled, LinksSetWidgetStyled, LinkStyled, PsychologistLinkStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const LinksSetWidget: AppFCC<LinksSetWidgetProps> = props => {
  const {
    clientButtonText = 'Выбрать психолога',
    className,
    mode
  } = props;
  const navigator = useNavigator();
  const url = navigator.getUrl();
  const match = navigator.findMatch(url);
  const homePageRoute = ROUTES.homePage.index;
  const clientRouteDescriptor = useMemo(() => {
    if (match?.route === homePageRoute) {
      return ROUTES.search.index.getDescriptor();
    }
    return ROUTES.index.getDescriptor({
      [QUERY_PARAM.SOURCE]: POPUP.LANDING_CLIENT_REGISTER_MODAL
    }, {
      resetScroll: false
    });
  }, [homePageRoute, match?.route]);
  const specialistRouteDescriptor = useMemo(() => ROUTES.psychologist.index.getDescriptor(), []);
  return _jsxs(LinksSetWidgetStyled, {
    className: className,
    children: [_jsxs(LinkStyled //
    , {
      color: BUTTON_BASE_COLORED_COLOR.LIGHT_PINK,
      routeDescriptor: clientRouteDescriptor,
      children: [clientButtonText, _jsx(ArrowRightIconStyled, {})]
    }), _jsx(PsychologistLinkStyled, {
      routeDescriptor: specialistRouteDescriptor,
      mode: mode,
      children: "\u044F - \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433"
    })]
  });
};