import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { Link, LinkExternal } from '@managers/links';
import { ContentDividerStyled, ContentLinksStyled, ContentLinkStyled, ContentLogoLinkStyled, ContentLogoStyled, ContentStyled, LinkContentStyled, LinkStyled, LinksWrapStyled } from './styled';
import { ContentMobileProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ContentTablet: AppFCC<ContentMobileProps> = props => {
  const {
    indexRouteDescriptor,
    linksProps,
    emailLinkProps,
    contactLinkProps,
    copyright,
    domain
  } = props;
  return _jsxs(ContentStyled, {
    children: [_jsx(ContentLogoLinkStyled, {
      className: "tablet:float-left tablet-h:float-left",
      routeDescriptor: indexRouteDescriptor,
      children: _jsx(ContentLogoStyled //
      , {
        src: "/images/logo-footer.svg",
        alt: domain
      })
    }), _jsxs(LinkStyled, {
      className: "tablet:float-right tablet-h:float-right",
      routeDescriptor: indexRouteDescriptor,
      children: ["\xA9\xA0", copyright]
    }), _jsxs(ContentLinksStyled, {
      children: [linksProps.map(({
        href,
        children
      }, index) => {
        return _jsx(ContentLinkStyled //
        , {
          href: href,
          children: children
        }, index);
      }), _jsx(LinksWrapStyled, {
        children: _jsxs(LinkContentStyled, {
          children: [_jsx(Link, {
            routeDescriptor: ROUTES.contacts.index.getDescriptor(),
            children: contactLinkProps.children
          }), _jsx(ContentDividerStyled, {
            children: "|"
          }), _jsx(LinkExternal, {
            href: emailLinkProps.href,
            children: emailLinkProps.children
          })]
        })
      })]
    })]
  });
};