import { useCallback, useEffect, useMemo, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';
import { AppFC } from '@types';
import { Emoji } from '@icons';
import { HeroSectionSliderWidgetSlideBodyStyled, heroSectionSliderWidgetSlideEnterActiveClassName, heroSectionSliderWidgetSlideEnterClassName, heroSectionSliderWidgetSlideExitClassName } from './styled';
import { heroSectionSliderWidgetSlideClassName, HeroSectionSliderWidgetStyled } from './styled';

/**
 * Emoji images and titles were taken here: https://github.com/dherault/react-apple-emojis/blob/master/src/data.json
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const slides = [_jsxs("span", {
  children: ["#\u043F\u043E\u0433\u043E\u0432\u043E\u0440\u0438\u0442\u044C \u0441\u043E\xA0\u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u043E\u043C \u043F\u0440\u043E\u0441\u0442\u043E\xA0\u043D\u0430\u043F\u0438\u0441\u0430\u0432 \u0435\u043C\u0443", ' ', _jsx(Emoji, {
    name: "sparkles"
  })]
}, 0), _jsxs("span", {
  children: ["#\u0443\u043B\u0443\u0447\u0448\u0438\u0442\u044C \u0441\u0432\u043E\u0438 \u043E\u0442\u043D\u043E\u0448\u0435\u043D\u0438\u044F\xA0\u0432\xA0\u0441\u0435\u043C\u044C\u0435 ", _jsx(Emoji, {
    name: "hugging-face"
  })]
}, 1), _jsxs("span", {
  children: ["#\u043D\u0430\u043B\u0430\u0434\u0438\u0442\u044C \u0441\u0432\u043E\u0438 \u043E\u0442\u043D\u043E\u0448\u0435\u043D\u0438\u044F\xA0\u0432\xA0\u043F\u0430\u0440\u0435 ", _jsx(Emoji, {
    name: "red-heart"
  })]
}, 2), _jsxs("span", {
  children: ["#\u0441\u043F\u0440\u0430\u0432\u0438\u0442\u044C\u0441\u044F \u0441\xA0\u0442\u0440\u0435\u0432\u043E\u0436\u043D\u043E\u0441\u0442\u044C\u044E \u0438\xA0\u0441\u0442\u0440\u0435\u0441\u0441\u043E\u043C", ' ', _jsx(Emoji, {
    name: "smiling-face"
  })]
}, 3), _jsxs("span", {
  children: ["#\u0438\u0437\u0431\u0430\u0432\u0438\u0442\u044C\u0441\u044F \u043E\u0442 \u0447\u0443\u0432\u0441\u0442\u0432\u0430 \u043E\u0434\u0438\u043D\u043E\u0447\u0435\u0441\u0442\u0432\u0430 ", _jsx(Emoji, {
    name: "cowboy-hat-face"
  })]
}, 4), _jsxs("span", {
  children: ["#\u043D\u0430\u0441\u043B\u0430\u0434\u0438\u0442\u044C\u0441\u044F \u0441\u0430\u043C\u043E\u0440\u0435\u0430\u043B\u0438\u0437\u0430\u0446\u0438\u0435\u0439 ", _jsx(Emoji, {
    name: "smiling-face-with-sunglasses"
  })]
}, 5)];
export const HeroSectionSliderWidget: AppFC = () => {
  const [counter, setCounter] = useState(0);
  const nextSlide = useCallback(() => {
    setCounter(counter => {
      const nextSlide = counter + 1 < slides.length //
      ? counter + 1 : 0;
      return nextSlide;
    });
  }, []);
  useEffect(() => {
    const timer = setInterval(nextSlide, 4500);
    return () => {
      clearTimeout(timer);
    };
  }, [nextSlide]);
  const transitionClassNames: CSSTransitionClassNames = useMemo(() => ({
    enter: heroSectionSliderWidgetSlideEnterClassName,
    enterActive: heroSectionSliderWidgetSlideEnterActiveClassName,
    exit: heroSectionSliderWidgetSlideExitClassName
  }), []);
  const timeouts = useMemo(() => ({
    exit: 500
  }), []);
  return _jsx(HeroSectionSliderWidgetStyled, {
    children: _jsx(TransitionGroup, {
      component: null,
      children: _jsx(CSSTransition, {
        className: heroSectionSliderWidgetSlideClassName,
        classNames: transitionClassNames,
        timeout: timeouts,
        children: _jsx(HeroSectionSliderWidgetSlideBodyStyled, {
          children: slides[counter]
        })
      }, counter)
    })
  });
};